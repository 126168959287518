<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/store/components/CodeIpnut.vue
 * @Author: 张兴业
 * @Date: 2020-12-09 23:41:13
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-14 15:24:18
-->

<template>
  <div class="code_input">
    <el-form-item label-width="0px" prop="code">
      <el-input
        v-model="code"
        name="code"
        placeholder="请输入短信验证码"
        class="code"
        @input="change"
      >
        <template slot="append">
          <!-- <span
            v-loading="sending"
            v-if="!showTime"
            :class="disabledSend ? 'disable_send' : 'can_send'"
            @click="send"
          >
            {{ senderText }}
          </span> -->
          <el-button
            v-if="!showTime || sending"
            :loading="sending"
            type="text"
            :class="disabledSend ? 'disable_send' : 'can_send'"
            @click="send"
          >
            {{ senderText }}
          </el-button>
          <van-count-down
            v-if="showTime && !sending"
            :time="time"
            format="ss秒后重发"
            @finish="finish"
          />
        </template>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: ["value", "disabledSend", "sending"],
  data() {
    return {
      code: this.value,
      showTime: false,
      senderText: "发送验证码",
      time: 60000
    };
  },
  methods: {
    change(value) {
      this.$emit("input", value);
    },
    send() {
      this.$emit("send", () => {
        this.showTime = true;
      });
    },
    finish() {
      this.senderText = "重新发送";
      this.showTime = false;
    }
  }
};
</script>

<style lang="less" scoped>
.code_input {
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
  }
}

::v-deep .el-input-group__append {
  background: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  cursor: pointer;
}

.disable_send {
  color: rgba(0, 0, 0, 0.38) !important;
}

.can_send {
  color: #0091ff !important;
}
</style>
